<template>
  <!-- 讨论组分类Popover -->
  <el-dialog
    title=""
    :visible.sync="
      $store.state.discussionGroupClassification
        .hasDiscussionGroupClassificationPopover
    "
    custom-class="hasDiscussionGroupClassificationPopover"
    :close-on-click-modal="false"
    center
    :destroy-on-close="true"
  >
    <div class="discussion-header">
      <div style="height: 100%; display: flex; align-items: center">
        <i
          v-if="groupRouters.length > 1"
          class="el-icon-arrow-left"
          style="cursor: pointer; font-size: 20px; margin-top: 1px"
          @click="back"
        ></i>

        <div v-if="groupRouters.length > 1" class="line"></div>

        <span
          class="iconfont_Me icon-a-lujing13404"
          style="font-size: 20px; color: #333333; margin-right: 12px"
        ></span>
        <!-- 路径 -->
        <div
          class="groupRouters"
          :style="{ width: groupRouters.length > 1 ? '63%' : '69%' }"
        >
          <template v-if="groupRouters.length <= 4">
            <span
              v-for="(item, inx) in groupRouters"
              :key="inx"
              @click="goPage(item, inx)"
              :class="inx == groupRouters.length - 1 ? 'active' : ''"
            >
              <span v-if="inx != 0">/</span>
              <i v-if="strLength(item.text) <= maxNameLength">{{
                item.text
              }}</i>
              <i v-if="strLength(item.text) > maxNameLength">{{
                inx == 0
                  ? item.text
                  : substring(item.text, maxNameLength) + "..."
              }}</i>
            </span>
          </template>
          <template v-if="groupRouters.length > 4">
            <span @click="goHome()">{{ $t("myCategory") }}>...></span>
            <span
              v-for="(item, inx) in groupRouters.slice(-4)"
              :key="inx"
              @click="goPage(item, inx + (groupRouters.length - 4))"
              :class="inx == groupRouters.length - 1 ? 'active' : ''"
            >
              <span v-if="inx != 0">/</span>
              <i v-if="strLength(item.text) <= maxNameLength">{{
                item.text
              }}</i>
              <i v-if="strLength(item.text) > maxNameLength"
                >{{ substring(item.text, maxNameLength) }}...</i
              >
            </span>
          </template>
        </div>
        <!-- 搜索 -->
        <div class="discussion-input">
          <el-input
            class="class-input"
            size="mini"
            ref="inputs"
            v-model="searchClassValue"
            @keyup.enter.native="handlerSearchClass"
          >
            <template #suffix>
              <i
                class="el-icon-search imelink-searchbar__submit"
                @click="handlerSearchClass"
              ></i>
            </template>
          </el-input>
        </div>
        <!-- 关闭 -->
        <div class="discussion-close" @click="close">
          <i class="el-icon-close"></i>
        </div>
      </div>
    </div>
    <div class="discussion-add">
      <img @click="addClass" src="../../../assets/images/class/add-class.png" />
    </div>
    <el-progress
      v-if="percentage < 100"
      :percentage="percentage"
      :show-text="false"
      stroke-linecap="dashboard"
    >
    </el-progress>
    <!-- 用个div占位，页面抖动 -->
    <div v-else style="width: 100%; height: 1px"></div>
    <div class="class-cont">
      <!-- 搜索结果 -->
      <template v-if="showSearchWrap">
        <div
          class="cont-wrap search-warp"
          v-scroll="{
            distance: 100,
            onBottom: _onSearchClassListBottom,
          }"
        >
          <!-- 文件夹 -->
          <template
            v-if="searchFolderArr.length > 0 || searchGroupArr.length > 0"
          >
            <div class="folder-wrap">
              <div
                class="cont-item"
                :class="inx == isCheckedFloderIndex ? 'isClicked' : ''"
                v-for="(item, inx) in searchFolderArr"
                :key="item.id"
                @click="isClick(item, inx, 'search')"
                @contextmenu.prevent="rowContextmenu(inx)"
              >
                <img src="../../../assets/images/class/folder-icon.png" />
                <div
                  style="
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    margin-top: 10px;
                  "
                >
                  <div class="item-name">{{ item.nodeData }}</div>
                  <div style="align-self: end" v-if="item.childrenCounter > 0">
                    ({{ item.childrenCounter }})
                  </div>
                </div>
                <div class="item-info">
                  {{
                    item.parent.nodeData
                      ? "../" + item.parent.nodeData
                      : $t("myCategory")
                  }}
                </div>
                <div class="opera" @click.stop>
                  <el-popover
                    placement="bottom"
                    width="80"
                    popper-class="commonPopoverForPersonalCenter xxx"
                    trigger="hover"
                  >
                    <div class="operatingMenuBox">
                      <div
                        class="operatingMenuIconBox"
                        v-for="(c, index) in operatingMenu"
                        :key="index"
                        @click.stop="selectThisMenuItem(index, item, 'search')"
                      >
                        <span
                          v-if="c.iconClassName"
                          class="iconfont_Me iconStyle"
                          :class="c.iconClassName"
                        ></span>
                      </div>
                    </div>
                    <span
                      class="iconfont_Me icon-a-zu5096"
                      slot="reference"
                    ></span>
                  </el-popover>
                </div>
              </div>
            </div>
            <!-- 群 -->
            <div class="group-wrap">
              <div
                class="group-item"
                v-for="item in searchGroupArr"
                :key="item.id"
                @click="handlerChatTo(item)"
              >
                <img :src="item.shortInfoVo.profilePhoto" />
                <div class="group-item-r">
                  <div class="name">{{ item.shortInfoVo.chatName }}</div>
                  <div class="time" v-if="item.shortInfoVo.lastMessage">
                    {{ item.shortInfoVo.lastMessage.sendTime | formatDate }}
                  </div>
                </div>
                <div class="opera" @click.stop>
                  <el-popover
                    placement="bottom"
                    width="80"
                    popper-class="commonPopoverForPersonalCenter"
                    trigger="hover"
                  >
                    <div class="operatingMenuBox">
                      <div
                        class="operatingMenuIconBox"
                        v-for="(c, index) in operatingGroupMenu"
                        :key="index"
                        @click.stop="selectThisGroupMenuItem(index, item)"
                      >
                        <span
                          v-if="c.iconClassName"
                          class="iconfont_Me iconStyle"
                          :class="c.iconClassName"
                        ></span>
                      </div>
                    </div>
                    <span
                      class="iconfont_Me icon-a-zu5096"
                      slot="reference"
                    ></span>
                  </el-popover>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="no-data">
              <img src="../../../assets/images/class/no-data-img.png" />
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div
          class="cont-wrap"
          v-scroll="{
            distance: 100,
            onBottom: _onClassListBottom,
          }"
        >
          <!-- 文件夹 -->
          <div class="folder-wrap" v-if="folderArr.length > 0">
            <div
              class="cont-item"
              :class="inx == isCheckedFloderIndex ? 'isClicked' : ''"
              v-for="(item, inx) in folderArr"
              :key="item.id"
              @click="isClick(item, inx)"
              @contextmenu.prevent="rowContextmenu(inx)"
            >
              <img src="../../../assets/images/class/folder-icon.png" />
              <div
                style="
                  display: flex;
                  align-items: center;
                  font-size: 12px;
                  margin-top: 10px;
                "
              >
                <div class="item-name">{{ item.nodeData }}</div>
                <div style="align-self: end" v-if="item.childrenCounter > 0">
                  ({{ item.childrenCounter }})
                </div>
              </div>
              <div class="opera" @click.stop>
                <el-popover
                  placement="bottom"
                  width="80"
                  popper-class="commonPopoverForPersonalCenter xxx"
                  trigger="hover"
                >
                  <div class="operatingMenuBox">
                    <div
                      class="operatingMenuIconBox"
                      v-for="(c, index) in operatingMenu"
                      :key="index"
                      @click.stop="selectThisMenuItem(index, item)"
                    >
                      <span
                        v-if="c.iconClassName"
                        class="iconfont_Me iconStyle"
                        :class="c.iconClassName"
                      ></span>
                    </div>
                  </div>
                  <span
                    class="iconfont_Me icon-a-zu5096"
                    slot="reference"
                  ></span>
                </el-popover>
              </div>
            </div>
          </div>
          <!-- 群 -->
          <div class="group-wrap" v-if="groupArr.length > 0">
            <div
              class="group-item"
              v-for="item in groupArr"
              :key="item.id"
              @click="handlerChatTo(item)"
            >
              <img :src="item.shortInfoVo.profilePhoto" />
              <div class="group-item-r">
                <div class="name">{{ item.shortInfoVo.chatName }}</div>
                <div class="time" v-if="item.shortInfoVo.lastMessage">
                  {{ item.shortInfoVo.lastMessage.sendTime | formatDate }}
                </div>
              </div>
              <div class="opera" @click.stop>
                <el-popover
                  placement="bottom"
                  width="80"
                  popper-class="commonPopoverForPersonalCenter"
                  trigger="hover"
                >
                  <div class="operatingMenuBox">
                    <div
                      class="operatingMenuIconBox"
                      v-for="(c, index) in operatingGroupMenu"
                      :key="index"
                      @click.stop="selectThisGroupMenuItem(index, item)"
                    >
                      <span
                        v-if="c.iconClassName"
                        class="iconfont_Me iconStyle"
                        :class="c.iconClassName"
                      ></span>
                    </div>
                  </div>
                  <span
                    class="iconfont_Me icon-a-zu5096"
                    slot="reference"
                  ></span>
                </el-popover>
              </div>
            </div>
          </div>
          <div
            v-if="folderArr.length == 0 && groupArr.length == 0"
            class="no-data"
          >
            <img src="../../../assets/images/class/no-data-img.png" />
          </div>
        </div>
      </template>
    </div>
  </el-dialog>
</template>
<script>
import {
  addChatClass,
  chatClassList,
  deleteClass,
  collectInfo,
} from "@/api/newVersion/discussionGroupClassification";
import { substring, strlen } from "@/utils/number";
export default {
  data() {
    return {
      expandedKeys: [],
      maxNameLength: 10,
      //群分组自定义路由
      groupRouters: [
        {
          name: "home",
          text: this.$t("myCategory"),
        },
      ],
      chatClassListPage: 1,
      chatClassListPageSize: 40,
      searchClassValue: "",
      percentage: 0,
      isCheckedFloderIndex: -1,
      folderArr: [],
      groupArr: [],
      operatingMenu: [
        {
          iconClassName: "icon-write",
          tooltipContent: this.$t("editpost"),
        },
        {
          iconClassName: "icon-a-gundongzu47",
          operatingFn: this.collectionAction,
          tooltipContent: this.$t("move"),
        },
        {
          iconClassName: "icon-Iconly-Light-Delete-copy",
          tooltipContent: this.$t("delete"),
        },
      ],
      visible: false,
      showSearchWrap: false,
      searchClassList: [],
      searchFolderArr: [],
      searchGroupArr: [],
      operatingGroupMenu: [
        {
          iconClassName: "icon-a-gundongzu47",
          operatingFn: this.collectionAction,
          tooltipContent: this.$t("move"),
        },
        {
          iconClassName: "icon-Iconly-Light-Delete-copy",
          tooltipContent: this.$t("delete"),
        },
      ],
    };
  },
  props: {},
  watch: {
    // 监听是否需要刷新收藏夹列表
    "$store.state.discussionGroupClassification.hasDiscussionGroupneedRefreshImFavoritesList": {
      async handler(val, old) {
        if (val && val !== old) {
          this.chatClassListPage = 1;
          await this.getChatClassList();
          this.$store.commit(
            "setHasDiscussionGroupneedRefreshImFavoritesList",
            false
          );
        }
      },
      deep: true,
    },
    //
    "$store.state.discussionGroupClassification.hasDiscussionGroupClassificationInfo": {
      async handler(val, old) {
        await this.getCollectInfo(val);
        this.chatClassListPage = 1;
        await this.getChatClassList(val, "par");
      },
      deep: true,
    },
  },
  computed: {
    // 当前收藏夹节点
    currentCollectListRouter() {
      if (this.groupRouters.length == 0) {
        this.groupRouters = [
          {
            name: "home",
            text: this.$t("myCategory"),
          },
        ];
      }
      return this.groupRouters[this.groupRouters.length - 1];
    },
  },
  mounted() {
    // 监听鼠标左键点击
    document.addEventListener("click", (e) => {
      this.visible = false;
    });
  },
  beforeDestroy() {
    // 监听鼠标左键点击
    document.removeEventListener("click", (e) => {
      this.visible = false;
    });
  },
  filters: {
    formatDate: function(value) {
      let date = new Date(Number(value));
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d;
    },
  },
  methods: {
    rowContextmenu(inx) {
      if (this.isCheckedFloderIndex == inx) {
        this.visible = !this.visible;
      } else {
        this.visible = true;
      }
      this.isCheckedFloderIndex = inx;
    },
    //添加分类
    addClass() {
      let parentId = null;
      if (this.currentCollectListRouter.id) {
        parentId = this.currentCollectListRouter.id;
      }
      this.$prompt(`${this.$t("newfolder")}（≤40）`, "", {
        confirmButtonText: " ",
        cancelButtonText: " ",
        center: true,
        showClose: true,
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn",
        inputPattern: /^.{1,40}$/,
        inputErrorMessage: this.$t("extralongtips"),
      })
        .then(async ({ value }) => {
          let params = {
            nodeData: value,
            nodeType: 0,
          };
          if (parentId) {
            params.parentId = parentId;
          }
          let result = await addChatClass(params);
          if (result.message == "success") {
            this.$message({
              type: "success",
              message: this.$t("addedSuccessfully"),
            });
            this.chatClassListPage = 1;
            await this.getChatClassList();
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        })
        .catch(() => {});
    },
    // 收藏列表拉取更多
    async _onClassListBottom() {
      this.chatClassListPage++;
      await this.getChatClassList();
    },
    // 获取收藏列表、
    async getChatClassList(data, type) {
      if (!data) {
        data = this.currentCollectListRouter;
      }
      this.percentage = 0;
      this.interVal = setInterval(() => {
        this.percentage++;
        if (this.percentage > 90) {
          clearInterval(this.interVal);
        }
      }, 100);
      this.visible = false;

      const pageInfo = {
        page: this.chatClassListPage,
        pageSize: this.chatClassListPageSize,
      };

      data = {
        ...data,
        ...pageInfo,
      };

      if (!data.parentId && data.id) {
        data.parentId = data.id;
      }

      let result;
      if (data && data.id) {
        // 直接进入文件对应的文件夹中
        if (type == "par") {
          result = await chatClassList({ id: data.parent.id, ...pageInfo });
        } else {
          result = await chatClassList(data);
        }
      } else {
        this.groupRouters = [
          {
            name: "home",
            text: this.$t("myCategory"),
          },
        ];
        result = await chatClassList(data);
      }

      if (result.code == 200) {
        this.percentage = 100;
        clearInterval(this.interVal);
        if (result.data.data.length != 0 || this.chatClassListPage == 1) {
          this.chatClassList =
            this.chatClassListPage == 1
              ? result.data.data
              : this.chatClassList.concat(result.data.data);
          this.folderArr = this.chatClassList.filter(
            (item) => item.nodeType == 0
          ); //文件夹
          this.groupArr = this.chatClassList.filter(
            (item) => item.nodeType == 1
          ); //群
        } else {
          this.chatClassListPage = result.data.pages;
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 选择操作菜单的选项
    selectThisMenuItem(index, operatingItem) {
      switch (index) {
        case 0:
          // 这一步是展示添加新收藏夹组件
          this.$store.commit("setNeedToShowInDialog", "editImFolderBlock");
          this.$store.commit("setNeedEditImFavoritesInfo", operatingItem);
          // 打开收藏操作弹窗
          this.$store.commit("setFavoritesDialogStatus", true);
          // 设置一下修改名字的类型
          this.$store.commit(
            "setModifyFolderNameType",
            "hasDiscussionGroupClassificationPopover"
          );
          this.visible = false;
          break;
        case 1:
          this.$store.commit(
            "setDiscussionGroupClassificationInfo",
            operatingItem
          );
          this.$store.commit(
            "setMoveDiscussionGroupClassificationPopoverVisible",
            true
          );
          break;
        case 2:
          this.$confirm("", "", {
            confirmButtonText: " ",
            cancelButtonText: " ",
            type: "warning",
            center: true,
            iconClass: "iconfont_Me icon-delet-fill",
            customClass: "my-confirm",
            cancelButtonClass: "el-icon-close my-confirm-btn",
            confirmButtonClass: "el-icon-check my-confirm-btn",
          })
            .then(async () => {
              await this.deleteSelectedFavorites(operatingItem);
            })
            .catch((e) => {
              this.visible = false;
            });
          break;
      }
    },
    // 删除选中的收藏夹
    async deleteSelectedFavorites(operatingItem) {
      let result = await deleteClass(operatingItem);
      if (result.code == 200) {
        this.$message({
          type: "success",
          message: this.$t("successfullyDeleted"),
        });
        this.chatClassListPage = 1;
        await this.getChatClassList();
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 获取收藏信息，用于初始化路由
    async getCollectInfo(params) {
      let result = await collectInfo(params.nodeData);

      if (result.code == 200) {
        let data = result.data.data;
        this.setName(data);
      }
    },
    setName(datas) {
      this.expandedKeys = [];
      this.groupRouters = [
        {
          name: "home",
          text: this.$t("myCategory"),
        },
      ];
      let parent = datas;
      while (parent) {
        parent = parent.parent;
        if (parent.id) {
          this.expandedKeys.push({
            id: parent.id,
            name: parent.nodeData,
            text: parent.nodeData,
          });
        }
      }
      this.groupRouters = [
        ...this.groupRouters,
        ...this.expandedKeys.reverse(),
      ];
    },

    async isClick(data, inx, type) {
      if (type == "search") {
        this.showSearchWrap = false;
        this.searchClassValue = "";
      }
      this.isCheckedFloderIndex = inx;
      let isRepeat = this.groupRouters.find(function(value) {
        return value.id == data.id;
      });
      if (!isRepeat) {
        this.groupRouters.push({
          name: data.nodeData,
          id: data.id,
          text: data.nodeData,
        });
      }
      this.chatClassListPage = 1;
      await this.getChatClassList();
    },
    async goPage(item, inx) {
      if (this.groupRouters.length > inx + 1) {
        this.groupRouters = this.groupRouters.splice(0, inx + 1);
      }
      this.showSearchWrap = false;
      this.searchClassValue = "";
      this.chatClassListPage = 1;
      await this.getChatClassList(item);
    },
    async check() {
      let discussionGroupClassificationInfo = this.$store.state
        .discussionGroupClassification.discussionGroupClassificationInfo;
      if (
        discussionGroupClassificationInfo &&
        discussionGroupClassificationInfo.isGroup
      ) {
        let params = {
          nodeData: discussionGroupClassificationInfo.id,
          nodeType: 1,
        };

        if (this.currentCollectListRouter.id) {
          params.parentId = this.currentCollectListRouter.id;
        }

        let result = await addChatClass(params);
        if (result.message == "success") {
          this.$message({
            type: "success",
            message: this.$t("addedSuccessfully"),
          });
          this.chatClassListPage = 1;
          await this.getChatClassList();
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      }
    },
    // 获取搜索数据
    async getsearchClassList(data) {
      this.percentage = 0;
      this.interVal = setInterval(() => {
        this.percentage++;
        if (this.percentage > 90) {
          clearInterval(this.interVal);
        }
      }, 100);
      this.visible = false;
      let result;
      result = await chatClassList(data);
      if (result.code == 200) {
        this.percentage = 100;
        clearInterval(this.interVal);
        if (result.data.data.length != 0 || this.chatClassListPage == 1) {
          this.searchClassList =
            this.chatClassListPage == 1
              ? result.data.data
              : this.searchClassList.concat(result.data.data);
          this.searchFolderArr = this.searchClassList.filter(
            (item) => item.nodeType == 0
          );
          this.searchGroupArr = this.searchClassList.filter(
            (item) => item.nodeType == 1
          );
        } else {
          this.chatClassListPage = result.data.pages;
        }
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 获取更多搜索数据
    async _onSearchClassListBottom() {
      this.chatClassListPage++;
      await this.handlerSearchClass(false);
    },
    handlerSearchClass(firstRefresh = true) {
      if (!this.searchClassValue) {
        return;
      }
      let parentId = null;
      if (this.groupRouters.length > 1) {
        parentId = this.currentCollectListRouter.id;
      }
      if (firstRefresh) {
        this.chatClassListPage = 1;
      }
      let params = {
        searchClassValue: this.searchClassValue,
        page: this.chatClassListPage,
        pageSize: this.chatClassListPageSize,
      };
      if (parentId) {
        params.parentId = parentId;
      }
      this.getsearchClassList(params);
      this.showSearchWrap = true;
    },
    selectThisGroupMenuItem(index, operatingItem) {
      switch (index) {
        case 0:
          this.$store.commit(
            "setDiscussionGroupClassificationInfo",
            operatingItem
          );
          this.$store.commit(
            "setMoveDiscussionGroupClassificationPopoverVisible",
            true
          );
          break;
        case 1:
          this.$confirm("", "", {
            confirmButtonText: " ",
            cancelButtonText: " ",
            type: "warning",
            center: true,
            iconClass: "iconfont_Me icon-delet-fill",
            customClass: "my-confirm",
            cancelButtonClass: "el-icon-close my-confirm-btn",
            confirmButtonClass: "el-icon-check my-confirm-btn",
          })
            .then(async () => {
              await this.deleteSelectedFavorites(operatingItem);
            })
            .catch((e) => {
              this.visible = false;
            });
          break;
      }
    },
    async handlerChatTo(item) {
      let params = {
        id: item.shortInfoVo.chatId,
        displayName: item.shortInfoVo.chatName.replace(/<\/?.+?\/?>/g, ""),
        avatar: item.shortInfoVo.profilePhoto,
        isGroup: true,
      };
      await this.$openIM(params, this.$store.state.imStore.IMWindowSize);
      this.close();
    },
    close() {
      this.searchClassValue = "";
      this.showSearchWrap = false;
      this.groupRouters = [
        {
          name: "home",
          text: this.$t("myCategory"),
        },
      ];
      this.$store.commit("setHasDiscussionGroupClassificationPopover", false);
    },
    back() {
      let len = this.groupRouters.length;
      if (len < 2) {
        return false;
      }
      this.goPage(this.groupRouters[len - 2], len - 2);
    },
    strLength(str) {
      return strlen(str);
    },
    substring(str, n) {
      return substring(str, n);
    },
    goHome() {
      this.goPage({ name: "home", text: this.$t("myCategory") }, 0);
    },
  },
};
</script>

<style lang="scss">
.el-dialog.hasDiscussionGroupClassificationPopover {
  max-width: 766px !important;
  width: 766px;
  user-select: none;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
  }

  .discussion-header {
    height: 49px;
    padding: 0px 22px 0 22px;
    background: #f9f9f9;

    .line {
      // width: 1px;
      height: 22px;
      border: 0.5px solid #cccccc;
      margin: 0 12px;
    }

    .groupRouters {
      width: 60%;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid #ededed;
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        cursor: pointer;
        white-space: nowrap;
        color: #999;

        &:last-child {
          i {
            font-style: normal;
            color: #333;
            font-weight: 700;
          }
        }
      }

      i {
        font-style: normal;
      }

      i {
        &:hover {
          color: #52bd68;
        }
      }
    }

    .discussion-input {
      display: flex;
      margin-right: 10px;

      input {
        // border: none;
        background: #ededed;
        border-radius: 13px;

        &:focus {
          background: #fff;
        }
      }

      .imelink-searchbar__submit {
        color: #666;
      }
    }

    .discussion-close {
      cursor: pointer;
      font-size: 20px;
    }
  }

  .discussion-add {
    height: 48px;
    border-bottom: 0.5px solid #ededed;
    display: flex;
    align-items: center;
    padding-left: 22px;
  }

  .class-cont {
    flex: 1;
    min-height: 0;
    height: 487px;

    .cont-wrap {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding: 22px;
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        box-shadow: inset 0 0 5px rgba(100, 100, 100, 0.1);
        background: rgba(100, 100, 100, 0.1);
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.05);
      }
      .folder-wrap {
        // overflow: hidden;
        margin-bottom: 2px;
        display: flex;
        flex-wrap: wrap;

        .favoritesRight {
          position: absolute;
          top: 10px;
          right: 4px;
          width: 20px;
          height: 10px;
          flex-shrink: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 2px;
            height: 10px;
          }
        }
      }

      .group-wrap {
        display: flex;
        flex-wrap: wrap;

        .group-item {
          width: 48%;
          margin: 0 10px 15px 0;
          border-radius: 4px;
          background: #fafafa;
          padding: 15px;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }

          .group-item-r {
            margin-left: 10px;

            .name {
              font-size: 13px;
              color: #333333;
              margin-bottom: 4px;
              overflow: hidden;
              text-overflow: ellipsis; // text-overflow css3的属性，当文本溢出时，显示省略号
              display: -webkit-box;
              -webkit-line-clamp: 1; // 设置两行文字溢出
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .time {
              font-size: 12px;
              color: #999999;
            }
          }

          .opera {
            margin-left: auto;
            font-size: 14px;
            color: #999;
            cursor: pointer;
          }
        }

        @media screen and (max-width: 1200px) {
          .group-item {
            width: 100%;
          }
        }
      }

      .cont-item {
        float: left;
        background: #fafafa;
        position: relative;
        width: 138px;
        height: 106px;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 22px;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding: 10px;

        &:hover {
          background: #fafafa;
        }

        .item-name {
          font-size: 12px;
          // margin-top: 10px;
          overflow: hidden;
          text-overflow: ellipsis; // text-overflow css3的属性，当文本溢出时，显示省略号
          display: -webkit-box;
          -webkit-line-clamp: 2; // 设置两行文字溢出
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        img {
          width: 36px;
          height: 32px;
        }

        &.isClicked {
          background: #fafafa;
        }

        .opera {
          font-size: 12px;
          color: #999;
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }

    .no-data {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 0 !important;
    }

    // 修改滚动条样式
    &::-webkit-scrollbar {
      width: 6px !important;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inspect006pxrgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba($color: #000000, $alpha: 0.3);
      -webkit-box-shadow: inspect006pxrgba(0, 0, 0, 0.5);
    }
  }

  .discussion-operat {
    padding: 20px 0 40px 0;
    display: flex;
    justify-content: center;

    .close {
      width: 90px;
      height: 34px;
      justify-content: center;
      display: flex;
      align-items: center;
      background: #e6e6e6;
      color: #fff;
      border-radius: 4px;
    }

    .check {
      width: 90px;
      height: 34px;
      background: #52bd68;
      justify-content: center;
      display: flex;
      align-items: center;
      color: #fff;
      border-radius: 4px;
    }
  }

  .search-warp {
    .folder-wrap {
      .cont-item {
        width: 138px;
        height: auto;
        border: 7px solid #fafafa;
        padding: 0;
        padding-top: 20px;
        background: #fff;

        &:hover {
          background: none;
        }

        .item-info {
          margin-top: 10px;
          font-size: 12px;
          background: #fafafa;
          width: 100%;
          padding: 8px 0;
        }

        .opera {
          font-size: 12px;
        }
      }
    }

    .search-group-item {
      width: 48%;
      margin-right: 10px;
      border-radius: 4px;
      background: #fafafa;
      padding: 7px;
      margin-bottom: 14px;
      box-sizing: border-box;

      .group-item-top {
        padding: 5px;
        width: 100%;
        background: #fff;
        display: flex;
        align-items: center;

        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }

        .group-item-r {
          margin-left: 10px;

          .name {
            font-size: 13px;
            color: #333333;
            margin-bottom: 4px;
            overflow: hidden;
            text-overflow: ellipsis; // text-overflow css3的属性，当文本溢出时，显示省略号
            display: -webkit-box;
            -webkit-line-clamp: 1; // 设置两行文字溢出
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .time {
            font-size: 12px;
            color: #999999;
          }
        }

        .opera {
          margin-left: auto;
          font-size: 14px;
          color: #999999;
          cursor: pointer;
        }
      }

      .group-item-bottom {
        margin-top: 10px;
        font-size: 12px;
        background: #fafafa;
        color: #333333;
        width: 100%;
        padding: 8px 4px;
      }
    }
  }
}
</style>
