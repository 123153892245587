var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "",
        visible:
          _vm.$store.state.discussionGroupClassification
            .hasDiscussionGroupClassificationPopover,
        "custom-class": "hasDiscussionGroupClassificationPopover",
        "close-on-click-modal": false,
        center: "",
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.discussionGroupClassification,
            "hasDiscussionGroupClassificationPopover",
            $event
          )
        }
      }
    },
    [
      _c("div", { staticClass: "discussion-header" }, [
        _c(
          "div",
          {
            staticStyle: {
              height: "100%",
              display: "flex",
              "align-items": "center"
            }
          },
          [
            _vm.groupRouters.length > 1
              ? _c("i", {
                  staticClass: "el-icon-arrow-left",
                  staticStyle: {
                    cursor: "pointer",
                    "font-size": "20px",
                    "margin-top": "1px"
                  },
                  on: { click: _vm.back }
                })
              : _vm._e(),
            _vm.groupRouters.length > 1
              ? _c("div", { staticClass: "line" })
              : _vm._e(),
            _c("span", {
              staticClass: "iconfont_Me icon-a-lujing13404",
              staticStyle: {
                "font-size": "20px",
                color: "#333333",
                "margin-right": "12px"
              }
            }),
            _c(
              "div",
              {
                staticClass: "groupRouters",
                style: { width: _vm.groupRouters.length > 1 ? "63%" : "69%" }
              },
              [
                _vm.groupRouters.length <= 4
                  ? _vm._l(_vm.groupRouters, function(item, inx) {
                      return _c(
                        "span",
                        {
                          key: inx,
                          class:
                            inx == _vm.groupRouters.length - 1 ? "active" : "",
                          on: {
                            click: function($event) {
                              return _vm.goPage(item, inx)
                            }
                          }
                        },
                        [
                          inx != 0 ? _c("span", [_vm._v("/")]) : _vm._e(),
                          _vm.strLength(item.text) <= _vm.maxNameLength
                            ? _c("i", [_vm._v(_vm._s(item.text))])
                            : _vm._e(),
                          _vm.strLength(item.text) > _vm.maxNameLength
                            ? _c("i", [
                                _vm._v(
                                  _vm._s(
                                    inx == 0
                                      ? item.text
                                      : _vm.substring(
                                          item.text,
                                          _vm.maxNameLength
                                        ) + "..."
                                  )
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    })
                  : _vm._e(),
                _vm.groupRouters.length > 4
                  ? [
                      _c(
                        "span",
                        {
                          on: {
                            click: function($event) {
                              return _vm.goHome()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("myCategory")) + ">...>")]
                      ),
                      _vm._l(_vm.groupRouters.slice(-4), function(item, inx) {
                        return _c(
                          "span",
                          {
                            key: inx,
                            class:
                              inx == _vm.groupRouters.length - 1
                                ? "active"
                                : "",
                            on: {
                              click: function($event) {
                                _vm.goPage(
                                  item,
                                  inx + (_vm.groupRouters.length - 4)
                                )
                              }
                            }
                          },
                          [
                            inx != 0 ? _c("span", [_vm._v("/")]) : _vm._e(),
                            _vm.strLength(item.text) <= _vm.maxNameLength
                              ? _c("i", [_vm._v(_vm._s(item.text))])
                              : _vm._e(),
                            _vm.strLength(item.text) > _vm.maxNameLength
                              ? _c("i", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.substring(
                                        item.text,
                                        _vm.maxNameLength
                                      )
                                    ) + "..."
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      })
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "discussion-input" },
              [
                _c("el-input", {
                  ref: "inputs",
                  staticClass: "class-input",
                  attrs: { size: "mini" },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handlerSearchClass.apply(null, arguments)
                    }
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "suffix",
                      fn: function() {
                        return [
                          _c("i", {
                            staticClass:
                              "el-icon-search imelink-searchbar__submit",
                            on: { click: _vm.handlerSearchClass }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ]),
                  model: {
                    value: _vm.searchClassValue,
                    callback: function($$v) {
                      _vm.searchClassValue = $$v
                    },
                    expression: "searchClassValue"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "discussion-close", on: { click: _vm.close } },
              [_c("i", { staticClass: "el-icon-close" })]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "discussion-add" }, [
        _c("img", {
          attrs: { src: require("../../../assets/images/class/add-class.png") },
          on: { click: _vm.addClass }
        })
      ]),
      _vm.percentage < 100
        ? _c("el-progress", {
            attrs: {
              percentage: _vm.percentage,
              "show-text": false,
              "stroke-linecap": "dashboard"
            }
          })
        : _c("div", { staticStyle: { width: "100%", height: "1px" } }),
      _c(
        "div",
        { staticClass: "class-cont" },
        [
          _vm.showSearchWrap
            ? [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "scroll",
                        rawName: "v-scroll",
                        value: {
                          distance: 100,
                          onBottom: _vm._onSearchClassListBottom
                        },
                        expression:
                          "{\n          distance: 100,\n          onBottom: _onSearchClassListBottom,\n        }"
                      }
                    ],
                    staticClass: "cont-wrap search-warp"
                  },
                  [
                    _vm.searchFolderArr.length > 0 ||
                    _vm.searchGroupArr.length > 0
                      ? [
                          _c(
                            "div",
                            { staticClass: "folder-wrap" },
                            _vm._l(_vm.searchFolderArr, function(item, inx) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "cont-item",
                                  class:
                                    inx == _vm.isCheckedFloderIndex
                                      ? "isClicked"
                                      : "",
                                  on: {
                                    click: function($event) {
                                      return _vm.isClick(item, inx, "search")
                                    },
                                    contextmenu: function($event) {
                                      $event.preventDefault()
                                      return _vm.rowContextmenu(inx)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../../assets/images/class/folder-icon.png")
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        "font-size": "12px",
                                        "margin-top": "10px"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "item-name" }, [
                                        _vm._v(_vm._s(item.nodeData))
                                      ]),
                                      item.childrenCounter > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "align-self": "end"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  (" +
                                                  _vm._s(item.childrenCounter) +
                                                  ")\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _c("div", { staticClass: "item-info" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          item.parent.nodeData
                                            ? "../" + item.parent.nodeData
                                            : _vm.$t("myCategory")
                                        ) +
                                        "\n              "
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "opera",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            width: "80",
                                            "popper-class":
                                              "commonPopoverForPersonalCenter xxx",
                                            trigger: "hover"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "operatingMenuBox" },
                                            _vm._l(_vm.operatingMenu, function(
                                              c,
                                              index
                                            ) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "operatingMenuIconBox",
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.selectThisMenuItem(
                                                        index,
                                                        item,
                                                        "search"
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  c.iconClassName
                                                    ? _c("span", {
                                                        staticClass:
                                                          "iconfont_Me iconStyle",
                                                        class: c.iconClassName
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            }),
                                            0
                                          ),
                                          _c("span", {
                                            staticClass:
                                              "iconfont_Me icon-a-zu5096",
                                            attrs: { slot: "reference" },
                                            slot: "reference"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "group-wrap" },
                            _vm._l(_vm.searchGroupArr, function(item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass: "group-item",
                                  on: {
                                    click: function($event) {
                                      return _vm.handlerChatTo(item)
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: item.shortInfoVo.profilePhoto
                                    }
                                  }),
                                  _c("div", { staticClass: "group-item-r" }, [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.shortInfoVo.chatName))
                                    ]),
                                    item.shortInfoVo.lastMessage
                                      ? _c("div", { staticClass: "time" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  item.shortInfoVo.lastMessage
                                                    .sendTime
                                                )
                                              ) +
                                              "\n                "
                                          )
                                        ])
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "opera",
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "bottom",
                                            width: "80",
                                            "popper-class":
                                              "commonPopoverForPersonalCenter",
                                            trigger: "hover"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "operatingMenuBox" },
                                            _vm._l(
                                              _vm.operatingGroupMenu,
                                              function(c, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "operatingMenuIconBox",
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.selectThisGroupMenuItem(
                                                          index,
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    c.iconClassName
                                                      ? _c("span", {
                                                          staticClass:
                                                            "iconfont_Me iconStyle",
                                                          class: c.iconClassName
                                                        })
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c("span", {
                                            staticClass:
                                              "iconfont_Me icon-a-zu5096",
                                            attrs: { slot: "reference" },
                                            slot: "reference"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      : [
                          _c("div", { staticClass: "no-data" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../../assets/images/class/no-data-img.png")
                              }
                            })
                          ])
                        ]
                  ],
                  2
                )
              ]
            : [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "scroll",
                        rawName: "v-scroll",
                        value: {
                          distance: 100,
                          onBottom: _vm._onClassListBottom
                        },
                        expression:
                          "{\n          distance: 100,\n          onBottom: _onClassListBottom,\n        }"
                      }
                    ],
                    staticClass: "cont-wrap"
                  },
                  [
                    _vm.folderArr.length > 0
                      ? _c(
                          "div",
                          { staticClass: "folder-wrap" },
                          _vm._l(_vm.folderArr, function(item, inx) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "cont-item",
                                class:
                                  inx == _vm.isCheckedFloderIndex
                                    ? "isClicked"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.isClick(item, inx)
                                  },
                                  contextmenu: function($event) {
                                    $event.preventDefault()
                                    return _vm.rowContextmenu(inx)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../assets/images/class/folder-icon.png")
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                      "font-size": "12px",
                                      "margin-top": "10px"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "item-name" }, [
                                      _vm._v(_vm._s(item.nodeData))
                                    ]),
                                    item.childrenCounter > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: { "align-self": "end" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                (" +
                                                _vm._s(item.childrenCounter) +
                                                ")\n              "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "opera",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          width: "80",
                                          "popper-class":
                                            "commonPopoverForPersonalCenter xxx",
                                          trigger: "hover"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "operatingMenuBox" },
                                          _vm._l(_vm.operatingMenu, function(
                                            c,
                                            index
                                          ) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass:
                                                  "operatingMenuIconBox",
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.selectThisMenuItem(
                                                      index,
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                c.iconClassName
                                                  ? _c("span", {
                                                      staticClass:
                                                        "iconfont_Me iconStyle",
                                                      class: c.iconClassName
                                                    })
                                                  : _vm._e()
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                        _c("span", {
                                          staticClass:
                                            "iconfont_Me icon-a-zu5096",
                                          attrs: { slot: "reference" },
                                          slot: "reference"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.groupArr.length > 0
                      ? _c(
                          "div",
                          { staticClass: "group-wrap" },
                          _vm._l(_vm.groupArr, function(item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "group-item",
                                on: {
                                  click: function($event) {
                                    return _vm.handlerChatTo(item)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: { src: item.shortInfoVo.profilePhoto }
                                }),
                                _c("div", { staticClass: "group-item-r" }, [
                                  _c("div", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item.shortInfoVo.chatName))
                                  ]),
                                  item.shortInfoVo.lastMessage
                                    ? _c("div", { staticClass: "time" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                item.shortInfoVo.lastMessage
                                                  .sendTime
                                              )
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    : _vm._e()
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "opera",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "bottom",
                                          width: "80",
                                          "popper-class":
                                            "commonPopoverForPersonalCenter",
                                          trigger: "hover"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "operatingMenuBox" },
                                          _vm._l(
                                            _vm.operatingGroupMenu,
                                            function(c, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "operatingMenuIconBox",
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      return _vm.selectThisGroupMenuItem(
                                                        index,
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  c.iconClassName
                                                    ? _c("span", {
                                                        staticClass:
                                                          "iconfont_Me iconStyle",
                                                        class: c.iconClassName
                                                      })
                                                    : _vm._e()
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c("span", {
                                          staticClass:
                                            "iconfont_Me icon-a-zu5096",
                                          attrs: { slot: "reference" },
                                          slot: "reference"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.folderArr.length == 0 && _vm.groupArr.length == 0
                      ? _c("div", { staticClass: "no-data" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../../assets/images/class/no-data-img.png")
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }